// this is auto generated, don't try and change it
const routes = [
  {importPath: './src/routes/index.tsx', path: 'index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/index')},
  {importPath: './src/routes/404.tsx', path: '404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/404')},
  {importPath: './src/routes/403.tsx', path: '403', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/403')},
  {importPath: './src/routes/View/index.tsx', path: 'View/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/View/index')},
  {importPath: './src/routes/View/$publicId/index.tsx', path: 'View/:publicId/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/View/$publicId/index')},
  {importPath: './src/routes/View/$publicId/_Layout.tsx', path: 'View/:publicId/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/View/$publicId/_Layout')},
  {importPath: './src/routes/View/$publicId/Camera/$entityId/index.tsx', path: 'View/:publicId/Camera/:entityId/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/View/$publicId/Camera/$entityId/index')},
];

export default routes;
export type StrongRouteTypes =  | `/` | `/404` | `/403` | `/view` | `/view/${string}` | `/view/${string}/camera/${string}`;
export const routeMap = {
	'index': () : StrongRouteTypes => `/`,
	'404': () : StrongRouteTypes => `/404`,
	'403': () : StrongRouteTypes => `/403`,
	'View/index': () : StrongRouteTypes => `/view`,
	'View/:publicId/index': (publicId: string) : StrongRouteTypes => `/view/${publicId}`,
	'View/:publicId/Camera/:entityId/index': (publicId: string, entityId: string) : StrongRouteTypes => `/view/${publicId}/camera/${entityId}`,
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useService } from 'envoc-request';
import { createStrictContext } from 'envoc-strict-context';
import { PublicCameraDto, WebViewCameraService } from 'api';

type PasswordProtectedCameraGroup = {
  password: string;
  cameras: PublicCameraDto[];
};

const [ContextProvider, useCameraContext] = createStrictContext<{
  cameraList: PublicCameraDto[] | undefined;
  passwordProtectedCameras: PasswordProtectedCameraGroup[];
  loading: boolean;
  refetch: Function;
  error: any;
  tryPassword: (password: string) => Promise<PublicCameraDto[] | undefined>;
}>({
  name: 'Camera',
});

function CameraContextProvider({
  children,
  publicId,
}: React.PropsWithChildren<{ publicId: string }>) {
  const { resp, loading, submitRequest, error } = useService(
    WebViewCameraService.getPublicCameras,
    { publicId },
    {
      autoExecute: false,
    }
  );
  const [cameraList, setCameraList] = useState<PublicCameraDto[]>();

  const [passwordProtectedCameras, setPasswordProtectedCameras] = useState<
    PasswordProtectedCameraGroup[]
  >([]);

  const tryPassword = useCallback(
    async (password: string) => {
      const { result } =
        await WebViewCameraService.getPasswordProtectedPublicCameras({
          publicId,
          password,
        });
      if (result && result.length > 0) {
        setPasswordProtectedCameras((ppc) => [
          ...ppc,
          { password, cameras: result },
        ]);
      }
      return result;
    },
    [publicId]
  );

  const refetch = useCallback(() => {
    submitRequest();
    setPasswordProtectedCameras((ppc) => {
      ppc.forEach((p) => tryPassword(p.password).catch());
      return ppc.length === 0 ? ppc : [];
    });
  }, [submitRequest, tryPassword]);

  const fullCameraList = cameraList?.map((camera) =>
    camera.isPasswordProtected
      ? passwordProtectedCameras
          .reduce<PublicCameraDto[]>(
            (prev, cur) => prev.concat(cur.cameras),
            []
          )
          .find((x) => x.id === camera.id) ?? camera
      : camera
  );

  useEffect(() => {
    if (resp?.result) {
      setCameraList(resp.result);
    }
  }, [resp?.result]);

  useEffect(() => {
    if (publicId) {
      refetch();
      return;
    }
    setCameraList(undefined);
    setPasswordProtectedCameras([]);
  }, [publicId, refetch]);

  useEffect(() => {
    if (loading) {
      setCameraList(undefined);
      setPasswordProtectedCameras([]);
    }
  }, [loading]);

  const value = useMemo(() => {
    return {
      cameraList: fullCameraList,
      passwordProtectedCameras,
      loading,
      refetch,
      error,
      tryPassword,
    };
  }, [
    error,
    fullCameraList,
    loading,
    passwordProtectedCameras,
    refetch,
    tryPassword,
  ]);

  return <ContextProvider value={value}>{children}</ContextProvider>;
}

export { CameraContextProvider, useCameraContext };

import { Env } from './env-vars';

export enum EnvPlatformBaseUrl {
  Local = 'https://localhost:5001',
  Dev = 'https://pbjtv-platform-stage-web.azurewebsites.net',
  QA = 'https://pbjtv-platform-stage-web.azurewebsites.net',
  Stage = 'https://pbjtv-platform-stage-web.azurewebsites.net',
  Production = 'https://admin.peanutbutterandjellytv.com',
}

export function getPlatformBaseUrl(append?: string) {
  return append
    ? EnvPlatformBaseUrl[Env.name] + append
    : EnvPlatformBaseUrl[Env.name];
}
